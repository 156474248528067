/* eslint-disable react/no-danger */
import React, { useEffect } from 'react';
import { graphql } from 'gatsby';
import HeaderMain from '../components/HeaderMain';
import Footer from '../components/Footer';
import HeroSingle from '../components/pages/HeroSingle';
import LineFull from '../images/linefull.jpg';
import Greenlogo from '../images/greenlogo.jpg';
import SEO from '../components/seo';
import '../styles/app.scss';

const openplay = ({ data }) => {
    const { wordpressPage: post } = data;
    const test_title = post.yoast_title;
    //const new_seo_title = test_title.replace('&#039;', "'");

    return (
        <>
            <HeaderMain />
            <HeroSingle pageTitle={post.title} />
            <section className="page-section smallestwdt">
                <div className="container">
                    <h2 className="bluetxt openplaytxt">
                        All Open Play sessions must be booked.
                    </h2>
                    <div className="openplft">
                        <img
                            src={
                                post.acf.open_play_image_left.localFile
                                    .childImageSharp.fluid.src
                            }
                            alt="nonprofitimg"
                            loading="lazy"
                        />
                        <p><a href="https://www.werockthespectrummoorabbin.com.au/social-stories/" class="wrtsbtn yellowbtn fullbtn mt-5" target="_blank">View Our Social Story</a></p>
                        <h4 style={{ color: '#006fb9', fontWeight: 'bold' }}>
                            ENTRY | Mondays – Fridays | 9.30-3.30
                        </h4>
                        <p style={{ marginTop: '15px', marginBottom: '15px', paddingBottom: '3%' }}>
                            <span style={{ color: '#006fb9', fontWeight: 'bold' }}>$15</span> entry when purchased in a 4-Visit Pack (shareable across siblings)<br />
                            <span style={{ color: '#006fb9', fontWeight: 'bold' }}>$18</span> entry for one-off visit
                        </p>

                        <h4 style={{ color: '#006fb9', fontWeight: 'bold' }}>
                            ENTRY | Weekends & after 3.30 on Weekdays
                            <br />(Limited capacity will apply to these sessions)
                        </h4>
                    <p style={{ marginTop: '15px', marginBottom: '15px', paddingBottom: '3%' }}>
                        <span style={{ color: '#006fb9', fontWeight: 'bold' }}>$25</span> Entry<br />
                        <span style={{ color: '#006fb9', fontWeight: 'bold' }}>$20</span> Sibling (Save $5)<br />
                        <span style={{ color: '#006fb9', fontWeight: 'bold' }}>$80</span> 4-Visit Pack (Save $20)<br />
                        <span style={{ color: '#006fb9', fontWeight: 'bold' }}>$180</span> 8-Visit Pack (Save $70)
                    </p>

                    </div>
                    <div
                        className="openprgt"
                        dangerouslySetInnerHTML={{
                            __html: post.acf.open_play_content_right,
                        }}
                    />
                    <div className="clearall"></div>

                    <div className="oplayhealwrap">
                        <div className="one_half lftoplayheal">
                            <h5 class="bluetxt">Pre-booked open play</h5>
                            <iframe
                                src="https://werockthespectrummoorabbin.wrtsfranchise.com/prebookedopenplay.php"
                                title="appointment"
                                className="healcode_widgets"
                            />
                        </div>
                    </div>

                </div>
            </section>
            <div className="container text_center mt-15">
                <img src={LineFull} alt="linefull" loading="lazy" />
            </div>
            <section className="page-section openphours">
                <div className="container smallestwdt">
                    <div className="twothirdcol">
                        <h2
                            className="bluetxt"
                            dangerouslySetInnerHTML={{
                                __html: post.acf.open_play_schedule_title,
                            }}
                        />
                        <ul className="startxt starlistspacing">
                            <li className="greentxt">
                                <a
                                    href="https://www.werockthespectrummoorabbin.com.au/schedule/"
                                    className="greentxt"
                                    style={{ fontFamily: 'hogfish_demoregular, sans-serif' }}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    Click here
                                </a>
                                {' '}to view our current week’s session times.
                            </li>
                     {/*       {post.acf.open_play_schedule_work_days.map(
                                (schedule, i) => (
                                    <li className="greentxt" key={i}>
                                        <span
                                            dangerouslySetInnerHTML={{
                                                __html:
                                                    schedule.open_play_schedule_day,
                                            }}
                                        />
                                        :&nbsp;
                                        <span
                                            dangerouslySetInnerHTML={{
                                                __html:
                                                    schedule.open_play_schedule_time,
                                            }}
                                        />
                                    </li>
                                )
                            )}
                            {post.acf.open_play_schedule_weekend.map(
                                (schedule, i) => (
                                    <li className="bluetxt" key={`ii_${i}`}>
                                        <span
                                            dangerouslySetInnerHTML={{
                                                __html:
                                                    schedule.open_play_schedule_weekend_day,
                                            }}
                                        />
                                        :&nbsp;
                                        <span
                                            dangerouslySetInnerHTML={{
                                                __html:
                                                    schedule.open_play_schedule_weekend_time,
                                            }}
                                        />
                                    </li> 
                                )
                            )}    */}
                            <p
                                className="pleasecallp"
                                dangerouslySetInnerHTML={{
                                    __html:
                                        post.acf
                                            .open_play_please_call_ahead_text,
                                }}
                            />
                        </ul>
                    </div>

                    <div className="onethirdcol">
                        <a
                            href="/register"
                            style={{ background: 'none', padding: 0 }}
                        >
                            <h6
                                className="greentxt"
                                dangerouslySetInnerHTML={{
                                    __html:
                                        post.acf.open_play_registration_title,
                                }}
                            />
                        </a>

                        {post.acf.open_play_register_widget_codes.map(
                            (scheduleCode, i) => (
                                <div
                                    className="healwrap"
                                    key={`key_${i}`}
                                    dangerouslySetInnerHTML={{
                                        __html:
                                            scheduleCode.open_play_register_widget_code,
                                    }}
                                />
                            )
                        )}

                    </div>
                </div>
            </section>

            <section
                className="whywelist page-section bg-primary text-white"
                id="openpbasic"
            >
                <div className="container flexwrap whywebluelist">
                    <div className="whylistlft flexbox whylisttxt todwn">
                 {/*       <img
                            className="whiteborderimg"
                            src={
                                post.acf.open_play_basic_info_image.localFile
                                    .childImageSharp.fluid.src
                            }
                            loading="lazy"
                            alt="open img"
                        />                  */}
                        <div style={{
                            position: 'relative',
                            width: '100%',
                            height: 0,
                            paddingTop: '141.4286%',
                            paddingBottom: 0,
                            boxShadow: '0 2px 8px 0 rgba(63,69,81,0.16)',
                            marginTop: '1.6em',
                            marginBottom: '0.9em',
                            overflow: 'hidden',
                            borderRadius: '8px',
                            willChange: 'transform'
                        }}>
                            <iframe
                                loading="lazy"
                                style={{
                                    position: 'absolute',
                                    width: '100%',
                                    height: '100%',
                                    top: 0,
                                    left: 0,
                                    border: 'none',
                                    padding: 0,
                                    margin: 0
                                }}
                                src="https://www.canva.com/design/DAE8muu_3DE/jAd3-wQgZkGjWcKlg53QJg/view?embed"
                                allowFullScreen
                                allow="fullscreen"
                            ></iframe>
                        </div>       
                    </div>

                    <div className="whylistrgt flexbox toup">
                        <h2
                            className="yellowtxt"
                            dangerouslySetInnerHTML={{
                                __html: post.acf.open_play_basic_info_title,
                            }}
                        />
                        <ul
                            className="startxt normalfontlist"
                            dangerouslySetInnerHTML={{
                                __html: post.acf.open_play_basic_info_content,
                            }}
                        />
                    </div>
                </div>
            </section>

            <section className="page-section bg-secondary text-white centersec">
                <div className="container smallestwdt">
                    <h2
                        className="yellowtxt"
                        dangerouslySetInnerHTML={{
                            __html: post.acf.open_play_membership_title,
                        }}
                    />
                    <p
                        className="biggertxt"
                        dangerouslySetInnerHTML={{
                            __html: post.acf.open_play_membership_description,
                        }}
                    />

                    {post.acf.open_play_membership_list.map((membership, i) => (
                        <h5 key={`kt_${i}`}>
                            <span
                                className="yellowtxt"
                                dangerouslySetInnerHTML={{
                                    __html:
                                        membership.open_play_membership_name,
                                }}
                            />
                            {' : '}
                            <span
                                dangerouslySetInnerHTML={{
                                    __html:
                                        membership.open_play_membership_price,
                                }}
                            />
                        </h5>
                    ))}
                </div>
            </section>

            <section className="page-section">
                <div className="container smallestwdt">
                    <div className="greenlft">
                        <img src={Greenlogo} alt="greenlogo" loading="lazy" />
                    </div>

                    <div className="greenrgt">
                        <h2
                            className="bluetxt"
                            dangerouslySetInnerHTML={{
                                __html: post.acf.open_play_green_products_title,
                            }}
                        />
                        <p
                            className="biggertxt lastitem"
                            dangerouslySetInnerHTML={{
                                __html:
                                    post.acf.open_play_green_products_content,
                            }}
                        />
                    </div>
                </div>
            </section>
            <Footer />
        </>
    );
};

export default openplay;

export const pageQuery = graphql`
    query($id: String!) {
        wordpressPage(id: { eq: $id }) {
            id
            title
            slug
            acf {
                open_play_basic_info_content
                open_play_basic_info_image {
                    localFile {
                        childImageSharp {
                            fluid {
                                srcWebp
                                src
                            }
                        }
                    }
                }
                open_play_basic_info_title
                open_play_content_right
                open_play_content_second
                open_play_green_products_content
                open_play_green_products_title
                open_play_image_left {
                    localFile {
                        childImageSharp {
                            fluid {
                                srcWebp
                                src
                            }
                        }
                    }
                }
                open_play_membership_description
                open_play_membership_list {
                    open_play_membership_name
                    open_play_membership_price
                }
                open_play_membership_title
                open_play_please_call_ahead_text
                open_play_registration_title
                open_play_register_widget_codes {
                    open_play_register_widget_code
                }
                open_play_schedule_title
                open_play_schedule_work_days {
                    open_play_schedule_day
                    open_play_schedule_time
                }
                open_play_schedule_weekend {
                    open_play_schedule_weekend_day
                    open_play_schedule_weekend_time
                }
            }
        }
    }
`;
